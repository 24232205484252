<template>
  <main-card-header :title="routerTitle"></main-card-header>
  <div class="flex justify-center flex-wrap content-evenly items-center">
    <small-form
      :title="routerTitle"
      instructions="Enter the new role name"
      :inputs="formItems"
      @resolve-form-submit="addRole"
      :errorMessage="newRoleError"
    ></small-form>
    <div>
      <div class="flex justify-center flex-wrap">
        <card-widget
          cardTitle="Roles"
          icon="person"
          :cardValue="getRolesCards.total"
        ></card-widget>
        <card-widget></card-widget>
      </div>
      <div class="flex justify-center flex-wrap">
        <card-widget></card-widget>
        <card-widget></card-widget>
      </div>
    </div>
  </div>
  <div class="flex justify-center items-center content-evenly">
    <small-dropdown-form
      title="Assign Role"
      instructions="Assign User to Role"
      @resolve-form-submit="assignRoleToUser"
      :errorMessage="assignRoleToUserError"
      :key="componentKey"
    >
      <label>Assign User:</label>
      <drop-down
        selectName="email"
        :values="getUsers"
        :onChange="resolveOnChange"
      />
      <label>To:</label>
      <drop-down
        selectName="role"
        :values="getRoles"
        :onChange="resolveOnChange"
      />
    </small-dropdown-form>
    <small-dropdown-form
      title="Remove User/Role"
      instructions="Remove role from user"
      @resolve-form-submit="removeRoleFromUser"
      :errorMessage="removeRoleFromUserError"
      :key="componentKey"
    >
      <label>Remove User:</label>
      <drop-down
        selectName="email"
        :values="getUsers"
        :onChange="resolveOnChange"
      />
      <label>From:</label>
      <drop-down
        selectName="role"
        :values="getRoles"
        :onChange="resolveOnChange"
      />
    </small-dropdown-form>
  </div>
  <template v-if="!isLoading">
    <dynamic-table title="Roles" @delete="deleteRole"></dynamic-table>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>
  <my-pagination
    v-if="!!getRolesCards.total"
    :options="options"
    v-model="page"
    :records="getRolesCards.total"
    :per-page="getRolesCards.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else />
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import dynamicTable from "@/components/layout/SA/table/table.vue";
import smallDropdownForm from "@/components/ui/forms/smallDropdownForm.vue";
import dropDown from "@/components/ui/forms/dropDown.vue";
import smallForm from "@/components/ui/forms/smallForm.vue";
import cardWidget from "@/components/ui/SA/cardWidget.vue";
export default {
  components: {
    dynamicTable,
    smallDropdownForm,
    dropDown,
    smallForm,
    cardWidget,
    blankPaginate,
  },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      page: 1,
      componentKey: 0,
      tableHeaders: ["NAME", "GUARD", "CREATED", "USERS", "ACTIONS"],
      formItems: [
        {
          type: "input",
          placeholder: "Role Name",
          fieldName: "name",
        },
      ],
      roleData: {},
      userData: {},
      localInputs: {},
      isLoading: false,
      newRoleError: null,
      assignRoleToUserError: null,
      removeRoleFromUserError: null,
    };
  },
  provide() {
    return { headers: this.tableHeaders };
  },
  created() {
    this.loadRoles(this.page);
    this.loadUsers();
  },
  methods: {
    myCallback(e) {
      this.loadRoles(e);
    },
    resolveOnChange(data) {
      this.localInputs[data.target.name] = data.target.value;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async loadRoles(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("admin/loadRoles", page);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadUsers() {
      // this.isLoading = true;
      try {
        await this.$store.dispatch("admin/loadUsers");
        // this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async deleteRole(id) {
      try {
        await this.$store.dispatch("admin/deleteRole", id);
        this.isLoading = false;
        this.$toast.error(`Role Deleted!`);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    async addRole(data) {
      try {
        await this.$store.dispatch("admin/addRole", data);
        this.newRoleError = null;
        this.isLoading = false;
        this.$toast.success(`Role Created!`);
      } catch (error) {
        this.error = error.message;
        this.newRoleError = error.message;
      }
    },
    async assignRoleToUser() {
      try {
        await this.$store.dispatch("admin/assignRoleToUser", this.localInputs);
        this.assignRoleToUserError = null;
        this.isLoading = false;
        this.forceRerender();
        this.$toast.success(`Role successfully assigned!`);
      } catch (error) {
        this.error = error.message;
        this.assignRoleToUserError = error.message;
      }
    },
    async removeRoleFromUser() {
      try {
        await this.$store.dispatch(
          "admin/removeRoleFromUser",
          this.localInputs
        );
        this.removeRoleFromUserError = null;
        this.isLoading = false;
        this.forceRerender();
        this.$toast.success(`User successfully removed!`);
      } catch (error) {
        this.error = error.message;
        this.removeRoleFromUserError = error.message;
      }
    },
  },
  computed: {
    routerTitle() {
      return this.$route.meta.title;
    },
    getRolesCards() {
      return this.$store.getters["admin/getRoles"];
    },
    getRoles() {
      return this.$store.getters["admin/getRoles"];
    },

    getUsers() {
      const data = this.$store.getters["admin/getUsers"];
      if (Object.keys(data).length !== 0) {
        data.data.forEach((item) => {
          this.userData[item.email] = item.email;
        });
        return this.userData;
      } else {
        return this.userData;
      }
    },
  },
};
</script>
